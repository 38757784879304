
/* ================================
 *
 * 共通
 *
 * ================================ */

/*---------------------------------------------------
 おすすめ吐き出し
---------------------------------------------------*/

.section_rank {
  width: 1000px;
  margin: 0 auto 120px;
}
.section_rank, .section_rank div, .section_rank dl, .section_rank ul {
  opacity : 1.0;
}
.section_rank .tit_box {
  width: 648px;
  margin: 0 auto 40px;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 20px 20px;
  text-align: center;
  font-size: 14px;
}
.section_rank h3 {
  font-size: 28px;
  letter-spacing: 1px;
  color: #beab90;
  line-height: 1;
}
.section_rank .box {
  width: 100%;
  height: 160px;
  background: #FFF;
  position: relative;
  border: 1px solid #141414;
}
.section_rank .box:first-child {
  height: 322px;
  margin-bottom: 40px;
  position: relative;
}
.section_rank .box:nth-child(2) {
  width: 487px;
  float: left;
}
.section_rank .box:nth-child(3) {
  width: 487px;
  float: right;
}
.section_rank .box:nth-child(2) .txt_box, .section_rank .box:nth-child(3) .txt_box {
  float: right;
  width: 240px;
  height: 100%;
  position: relative;
}
.section_rank .box a {
  width: 100%;
  height: 100%;
  display: block;
  padding: 14px;
  box-sizing: border-box;
  position: relative;
}
.section_rank .box:first-child a {
  padding: 0;
}
.section_rank .box .ph {
  width: 200px;
  height: 129px;
  overflow: hidden;
  float: left;
}
.section_rank .box:first-child .ph {
  width: 500px;
  height: 320px;
  overflow: hidden;
  position: relative;
}
.section_rank .box .ph.new:after {
  display: block;
  content: "NEW";
  width: 59px;
  height: 59px;
  color: #FFF;
  line-height: 59px;
  border-radius: 50%;
  background: #deb44c;
  position: absolute;
  text-align: center;
  top: 5px;
  left: 5px;
  font-family:"Playfair Display", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
}

.section_rank .box .ph img {
  width: 100%;
}
.section_rank .box .txt_box {
  float: right;
  width: calc(100% - 220px);
  position: relative;
  text-align: left;
}
.section_rank .box .txt_box:after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border: 3px solid transparent;
  border-left: 5px solid #2d2d2d;
  transition-duration: 200ms;
  z-index: 99;
}
.section_plan_list .box .txt_box:after {
  display: block;
  content: "";
  position: absolute;
  bottom: -25px;
  right: -7px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border: 3px solid transparent;
  border-left: 5px solid #2d2d2d;
  transition-duration: 200ms;
  z-index: 99;
}
.section_rank .box:first-child .txt_box {
  width: calc(100% - 500px);
  padding: 30px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.section_rank .box:first-child .txt_box:after {
  display: none;
}
.section_rank .box .title,
.section_plan_list .box .title {
  font-size: 16px;
  max-height: 72px;
  overflow: hidden;
  line-height: 1.4;
}
.section_rank .box:first-child .title {
  border-bottom: 1px dotted #2d2d2d;
  max-height: 120px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.section_rank .box:first-child .txt {
  padding-top: 20px;
  height: 74px;
}
.section_rank .box:nth-child(2) .txt,
.section_rank .box:nth-child(3) .txt{
  font-size: 13px;
  height: 60px;
  overflow: hidden;
  margin-top: 10px;
}

.section_rank .box .txt_box .btn_more02 {
  display: none;
  float: right;
  margin-top: 40px;
}
.section_rank .box:first-child .txt_box .btn_more02 {
  display: block;
}

.section_rank .box .date {
  font-size: 15px;
  margin-bottom: 10px;
}

.main_fair .section_rank .box ul.icon {
  text-align: left;
}

.section_rank .box:nth-child(2) .title,
.section_rank .box:nth-child(3) .title {
  max-height: 60px;
  font-size: 16px;
  margin-top: 8px;
}
.main_fair .section_rank .box:nth-child(2) .txt,
.main_fair .section_rank .box:nth-child(3) .txt {
  display: none;
}
.section_rank .box .txt_box .btn_more02 {
  display: none;
  float: right;
  margin-top: 10px;
}


/*---------------------------------------------------
 カテゴリーアイコン
---------------------------------------------------*/

ul.icon {
  text-align:inherit;
  max-height:20px;
  overflow:hidden;
}

ul.icon li {
    padding: 3px 10px 4px;
    color: #fff;
    text-align: center;
    line-height: 1.1em;
    font-size: 11px;
    margin-right: 3px;
    display: inline-block;
    width:auto;
    background:#ccc;
}

/*  Pager
 *---------------------------------------------------------- */
.pager li.next a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border: 4px solid transparent;
  border-left: 5px solid #fff;
  transition-duration: 200ms;
}
.pager li.before a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border: 4px solid transparent;
  border-right: 5px solid #fff;
  transition-duration: 200ms;
}
.pager {
  text-align: center;
  position: relative;
  z-index: 1;
  clear: both;
}
.pager li.next, section .pager li.before {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 239px;
  height: 37px;
  margin: 36px 10px;
  float: none;
}
.pager li.next a, section .pager li.before a {
  background: #2d2d2d;
  text-decoration: none;
  color: #fff;
  line-height: 40px;
  display: block;
}


/* ================================
 *
 * フェア
 *
 * ================================ */

/*---------------------------------------------------
 Fair_index
---------------------------------------------------*/

.main_fair .container {
  width: 100%;
}

.main_fair .bg_grege .section_fair_cal {
  margin-top: 0;
}
.main_fair {
  padding-bottom: 60px;
}
.fair_list_inner.day {
  width: 542px;
  height: 490px;
  padding: 20px;
  overflow: scroll;
  overflow-x: hidden;
}
.fair_list_inner li .tit, .fair_list_inner.day li .tit {
  font-size: 14px;
  padding: 7px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
  float: left;
  width: 100%;
}
.fair_list_inner li .caption, .fair_list_inner.day li .caption {
  font-size: 12px;
  height: 38px;
  overflow: hidden;
  line-height: 1.5;
  float: left;
  width: 100%;
}

.main_fair h3.tit {
  font-size: 18px;
  text-align: center;
  padding: 12px 20px;
  border-top: 1px solid #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
  margin-bottom: 32px;
}
.main_fair h3.tit_category {
  text-align: left;
}
.fair_list {
  width: 1000px;
  margin: 0 auto;
}
.fair_list .box {
  width: 487px;
  height: 160px;
  background: #FFF;
  float: left;
  position: relative;
  border: 1px solid #141414;
}
.fair_list .box {
  width: 100%;
  height: 322px;
  margin-bottom: 50px;
  float: none;
}
.fair_list .box a {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}
.fair_list .box .ph {
  width: 500px;
  height: 320px;
  overflow: hidden;
  float: left;
}
.fair_list .box .ph img {
  width: 100%;/*margin-top: -34px;*/
}
.fair_list .box .txt_box {
  position: relative;
    float: right;
    width: 462px;
    height: 100%;
    padding-top: 30px;
    padding-right: 30px;
    text-align: left;
    max-height: 126px;
    overflow: hidden;
}
.fair_list .box .txt_box ul.icon {
  text-align: left;
}
.fair_list .box .title {
  padding: 20px 0 10px;
  border-bottom: 1px dotted #2d2d2d;
  height: 120px;
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.4;
}
.fair_list .box .txt {
  font-size: 12px;
  height: 67px;
  overflow: hidden;
  padding-top: 12px;
}
.fair_list .box a:hover .txt {
  color: #2d2d2d;
}
.fair_list .box .txt_box .btn_more02 {
  float: right;
  margin-top: 10px;
}


/* calendar
------------------------------------------------- */

.section_fair .fair_all{
  position:relative;
}

.section_fair .fair_all::before{
  content:"";
  position:absolute;
  top:10px;
  left:50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width:1080px;
  z-index:1;
}

.section_fair .fair_all h3.tit{
  text-align:center;
  width:320px;
  background:#FFF;
  margin:0 auto 62px;
  font-size:17px;
  z-index:999;
  position: relative;
  padding:0;
  border:none;
}

.section_fair .fair_inner{
  width: 1056px;
	margin: 0 auto;
}

.fair_all > div .flame {
  position: absolute;
  top: -54px;
  right: -30px;
  z-index: 999;
}
.slick-initialized .slick-slide {
  display: block;
}
.fair_all .calendar_wrapper {
  float: left;
  width: 518px;
	height: 530px;
  position: relative;
  text-align: center;
  background: url(../img/top/calendar_bg.png) repeat-y;
}

.fair_all .calendar_wrapper .icon{
	position: absolute;
    content: "";
    background: url(../img/cmn/icon_calender.png);
    width: 145px;
    height: 109px;
    left: -15px;
    top: 13px;
}


.fair_all .calendar_wrapper .catch {
  font-size: 60px;
  line-height: .6;
  margin-top: 30px;
}
.fair_all .calendar_wrapper .fair_txt {
  margin-bottom: 0px;
}
.fair_all .calendar_wrapper .btn_fair {
  width: 203px;
  height: 42px;
  margin: 0 auto;
}
.fair_all .calendar_wrapper .btn_fair a {
  width: 100%;
  height: 100%;
  border: 1px solid #2d2d2d;
  display: block;
  text-align: center;
  line-height: 39px;
  padding-left: 0;
}
.fair_all .calendar_wrapper .btn_fair a::before {
  left: 10px;
}
.fair_all .slick_month {
  font-size: 70px;
  width: 95px;
  border-bottom: 1px solid #141414;
  margin: 0 auto 10px;
}
.fair_all .calendar_wrapper .slick-disabled {
  display: none !important;
}
.fair_all .slick_month {
  font-size: 20px;
  font-weight: bold;
  margin: 0 5px 0 0;
}
.fair_all .calendar_wrapper .slick-arrow {
  position: absolute;
  z-index: 2000;
  background: none;
  cursor: pointer;
  border-style: none;
  top: 140px;
}
/*.main_fair .fair_all .calendar_wrapper .slick-arrow {
  top: 0px;
}*/
.fair_all .calendar_wrapper .slick-prev {
  left: 50px;
	top: 176px;
}
.fair_all .calendar_wrapper .slick-prev::before {
  content: "<";
  margin-right: 5px;
  font-size: 20px;
}
.fair_all .calendar_wrapper .slick-next {
  right: 50px;
	top: 176px;
}
.fair_all .calendar_wrapper .slick-next::after {
  content: ">";
  margin-left: 5px;
  font-size: 20px;
}
.fair_all .calendar {
  outline: none;
  position: relative;
}
.fair_all .calendar .inner {
  width: 430px;
  margin: 46px auto 0;
  text-align: center;
}
/*.main_fair .fair_all .calendar .inner {
  padding-top: 36px;
}*/
.fair_all .calendar_wrapper .catch {
  text-align: center;
  margin-bottom: 10px;
}
.fair_all .calendar .inner .month {
  font-size: 70px;
    line-height: 1;
    position: relative;
    width: 95px;
    margin: 19px auto 15px;
    padding: 30px 0 19px;
    text-align: center;
    border-bottom: 1px solid #2d2d2d;
}
.fair_all .calendar .inner .month::before {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: bold;
  content: "";
  position: absolute;
  z-index: 99;
  top: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #beab90;
}
.fair_all .calendar .inner .month.mon1::before {
  content: "January";
}
.fair_all .calendar .inner .month.mon2::before {
  content: "February";
}
.fair_all .calendar .inner .month.mon3::before {
  content: "March";
}
.fair_all .calendar .inner .month.mon4::before {
  content: "April";
}
.fair_all .calendar .inner .month.mon5::before {
  content: "May";
}
.fair_all .calendar .inner .month.mon6::before {
  content: "June";
}
.fair_all .calendar .inner .month.mon7::before {
  content: "July";
}
.fair_all .calendar .inner .month.mon8::before {
  content: "August";
}
.fair_all .calendar .inner .month.mon9::before {
  content: "September";
}
.fair_all .calendar .inner .month.mon10::before {
  content: "October";
}
.fair_all .calendar .inner .month.mon11::before {
  content: "November";
}
.fair_all .calendar .inner .month.mon12::before {
  content: "December";
}
.fair_all .calendar .inner .fair_txt {
  margin: 0 0 30px 0;
  font-size: 13px;
}
.fair_all .calendar table {
  width: 100%;
  font-size: 13px;
  letter-spacing: 2px;
  table-layout: fixed;
/*  margin-bottom: 40px;*/
}
.fair_all .calendar table th {
  text-align: center;
  padding: 1px 14px;
}
/*.main_fair .fair_all .calendar table th {
  height: 42px;
}
*/
 .fair_all .calendar table th.sat{
  color: #0D5283;
}

 .fair_all .calendar table th.sun{
  color: #B60000;
}


.fair_all .calendar td {
  width: 37px;
  height: 45px;
  padding: 1px 13px;
}
.main_fair .fair_all .calendar td {
/*  height: 54px;*/
}
.fair_all .calendar td a {
  color: #beab90;
  font-size: 14px;
  text-decoration: underline;
}
.fair_all .calendar .inner table td .osusume a {
  background: #085580;
  padding: 10px 8px;
  color: #FFF;
  text-decoration: none;
  width: 33px;
  height: 33px;
  display: block;
}
.fair_all .calendar .inner table td .premium a {
  background: #deb44c;
  padding: 5px 8px;
  color: #FFF;
  text-decoration: none;
  width: 33px;
  height: 33px;
  display: block;
}
.fair_all .calendar .inner table td a:focus {
  background: #141414;
  color: #fff;
  text-decoration: none;
}
.anc_line {
  margin-top: -50px;
  padding-top: 30px;
}
.main_fair .main_top .section_fair .fair_all {
  margin-bottom: 0px;
}
.section_fair_cal {
  margin-bottom: 0;
  margin-top: 100px;
}
.section_fair_cal h4 {
  font-size: 34px;
  text-align: center;
  line-height: 1;
}
.section_fair_cal h4.en02 {
  font-size: 27px;
    font-weight: bold;
}
.section_fair_cal .catch {
  font-size: 14px;
  text-align: center;
  margin-bottom: 24px;
}
.fair_calendar {
  margin-top: 80px;
  margin-bottom: 50px;
  background: #fff;
  padding: 80px 25px 40px;
  box-shadow: 0 0 3px rgba(0,0,0,0.1);
  position: relative;
}
.fair_calendar .frame {
  position: absolute;
  top: -60px;
  left: -30px;
  width: 1063px;
}
.fair h3.tit_h3 {
  margin-bottom: 20px;
  text-align: center;
}
.fair h3.tit_h3 span {
  display: block;
  font-weight: 400;
  font-family: 'Playfair Display', serif;
  letter-spacing: 1px;
  font-size: 23px;
}
.month_title {
  width: 100%;
  height: 160px;
  margin: 0 auto 15px;
  text-align: center;
  position: relative;
}
.month_title li.btn_back a {
  display: block;
  float: left;
  padding-left: 30px;
  background: url(../fair/img/btn_prev.jpg) no-repeat 0 0;
  position: relative;
  z-index: 3;
}
.month_title li.btn_next a {
  display: block;
  float: right;
  padding-right: 30px;
  background: url(../fair/img/btn_next.jpg) no-repeat right 0;
  position: relative;
  z-index: 3;
}
.month_title li.tit_month {
  font-size: 65px;
  font-family: "Prata";
  letter-spacing: 2px;
  line-height: 1.0;
  position: relative;
}
.tbl_calender {
  width: 100%;
  border-collapse: separate;
}
.tbl_calender th {
  width: 144px;
  padding: 12px 0 10px;
  border-top: 1px solid #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
  text-align: center;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 1;
  font-family: "a-otf-ryumin-pr6n", 游明朝, YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", HG明朝E, "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.tbl_calender th:nth-child(6) {
  color: #0d5fa2;
}
.tbl_calender th:nth-child(7) {
  color: #d6543c;
}
.tbl_calender td {
  width: 144px;
  background: #f6f5f3;
  padding: 10px;
  border-right: 8px solid #fff;
  border-top: 8px solid #fff;
  vertical-align: top;
}
.tbl_calender td.past {
  width: 144px;
  background: #fff;
  padding: 0px;
  border-right: 8px solid #fff;
  border-top: 8px solid #fff;
  vertical-align: top;
}
.tbl_calender tr:nth-child(2) td {
  border-top: 20px solid #fff;
}
.tbl_calender td:nth-child(7n) {
  border-right: none;
}
.tbl_calender td p {
  text-decoration: none;
  text-align: left;
}
.tbl_calender td p a {
  color: #666;
  text-decoration: underline;
  padding-top: 3px;
}
.tbl_calender td p a:hover {
  color: #d45136;
}
.tbl_calender .day {
  width: 100%;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: center;
  font-family: "a-otf-ryumin-pr6n", 游明朝, YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", HG明朝E, "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}
.tbl_calender .ficon span {
  width: 100%;
  color: #FFF;
  padding: 3px 0;
  text-align: center;
  display: block;
  margin-bottom: 2px;
  line-height: 1.0;
  font-size: 12px;
}
.tbl_calender td .cal_box {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #ccc;
}
/* おすすめ吐き出し
------------------------------------------------- */

.special_fair {
  margin: 0 auto 32px;
  padding-top: 63px;
  width: 1056px;
}
.special_fair .inner {
  margin: 0 auto;
}
.special_fair .sub {
  font-size: 15px;
  line-height: 1;
  margin-bottom: 10px;
  color: #beab90;
}
.special_fair .ph {
  width: 520px;
  height: 335px;
  float: left;
  overflow: hidden;
}
.special_fair .ph img {
  width: 100%;
  height: auto;
}
.special_fair .txt_wrapper {
  width: 448px;
  float: right;
  text-align: left;
  padding-top: 30px;
}
.special_fair a:hover .txt_wrapper {
  color: #141414!important;
}
.special_fair .date {
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 1;
}
.special_fair .tit {
  height: 75px;
  padding-bottom: 0;
  margin-bottom: 15px;
  font-size: 18px;
  overflow: hidden;
  line-height: 1.5;
  border-bottom: 1px dotted #d2d2d2;
}
.special_fair ul.icon {
  text-align: left;
  margin-bottom: 10px;
}
.special_fair .ficon {
  margin-bottom: 15px;
}
.special_fair .caption {
  height: 60px;
  font-size: 13px;
  overflow: hidden;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #1f1f1f;
}
.special_fair .btn_more::before {
  content: ">";
  margin-right: 10px;
}
.main_top .fair_list {
  width: 505px!important;
  height: 513px;
  float: right;
}
.fair_list iframe {
  width: 505px;
  height: 530px;
  border: 1px solid #2d2d2d;
}
.fair_list_day {
  font-size: 14px;
  padding: 14px 30px;
  height: 40px;
  background: #2d2d2d;
  color: #fff;
  line-height: 1;
}
.fair_list_day02 {
  font-size: 13px;
  padding: 14px 20px;
  background: #141414;
  color: #fff;
  line-height: 1;
}
.fair_list_inner {
  width: 542px;
  height: 484px;
  padding: 20px;
  background: #fff;
  overflow: scroll;
  overflow-x: hidden;
}
.fair_list_inner li {
  margin-bottom: 15px;
  height: 88px;
}
.fair_list_inner li a {
  width: 100%;
  height: 88px;
  display: block;
}
.fair_list_inner li .date {
  text-align: center;
  font-size: 12px;
  float: left;
  line-height: 1.2;
  margin-right: 15px;
}
.fair_list_inner li .date .month {
  font-size: 20px;
}
.fair_list_inner li .date .day {
  font-size: 39px;
}
.fair_list_inner li .ph {
  width: 132px;
  height: 88px;
  overflow: hidden;
  float: left;
  margin-right: 14px;
}
.fair_list_inner li img {
  width: 100%;
}
.fair_list_inner li .txt_wrapper {
  width: 310px;
  text-align: left;
  float: left;
}
.fair_list_inner .txt_wrapper ul.icon {
  text-align: left;
  margin: 0 0 7px;
}
.fair_list_inner li .caption {
  font-size: 12px;
  height: 55px;
  overflow: hidden;
  line-height: 1.5;
  float: left;
  width: 100%;
}


/* Fair_detail
---------------------------------------------------*/

.section_fair_main {
  width: 1056px;
  background: #fff;
  padding: 40px;
  margin: 0 auto 30px;
}
.section_fair_main.detail, .section_fair_main.detail .inner, .section_fair_main.detail .unit, .section_fair_main.detail .unit div, .section_fair_main.detail .unit ul {
  opacity: 1.0;
  transform: none;
}
.section_fair_main .info_box {
  text-align: center;
}
.section_fair_main .date {
  font-size: 18px;
  margin-bottom: 5px;
  display: inline-block;
}
.section_fair_main .title {
  font-size: 22px;
  margin: 10px 0;
}
.section_fair_main .stit {
  font-size: 16px;
  margin-bottom: 20px;
}
.section_fair_main .icons {
  text-align: center;
  margin-top: 5px;
  height: 20px;
  overflow: hidden;
}
.section_fair_main .icons li {
  padding: 2px 10px 4px;
  color: #fff;
  text-align: center;
  line-height: 1.1em;
  font-size: 0.85em;
  margin-right: 3px!important;
  display: inline-block;
  width: auto!important;
  float: none!important;
}
.section_fair_main .txt {
  margin-bottom: 30px;
  font-size: 18px;
}
.section_fair_main .section_inner {
  margin-bottom: 36px;
}
.section_fair_main .photo {
  overflow: hidden;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.section_fair_main .photo img {
  width: 100%;
}
.section_fair_main .info {
}
.ficon {
  margin-bottom: 5px;
  display: inline-block;
}
.ficon .special {
  line-height: 1.1em;
  font-size: 13px;
  color: #fff;
  display: inline;
  white-space: nowrap;
  padding: 2px 10px 3px;
  margin: 0 3px 2px 0;
}
.ficon .special01 {
  background: #c63504;
}
.ficon .special02 {
  background: #c79600;
}
.ficon .special03 {
  background: #c9a389;
}
.ficon .special04 {
  background: #95a977;
}
.ficon .special05 {
  background: #d7a06a;
}
.section_fair_main .data {
  margin-bottom: 24px;
}
.section_fair_main .data table {
  margin-bottom: 8px;
  width: 100%;
  border-collapse: collapse;
}
.section_fair_main .data tbody tr {
  border-bottom: 1px rgba(0,0,0,0.2) solid;
}
.section_fair_main .data tbody tr:first-child {
  border-top: 1px rgba(0,0,0,0.2) solid;
}
.section_fair_main .data th, .section_fair_main .data td {
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
}
.section_fair_main .data th {
  padding: 10px 6px;
  color: #fff;
  background: #2d2d2d;
  border-right: 1px solid #333;
  letter-spacing: 1px;
}
.section_fair_main .data th:nth-child(1) {
  width: 40%;
}
.section_fair_main .data th:nth-child(2) {
  width: 15%;
}
.section_fair_main .data th:nth-child(3) {
  width: 45%;
}
.section_fair_main .data.date_box {
  margin: 60px 0;
}
.section_fair_main .data.date_box .tit {
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #1f1f1f;
  margin-bottom: 26px;
  line-height: 1.0;
}
.section_fair_main .data.date_box th {
  width: 30%;
  background: rgba(0,0,0,.08);
  color: #373a37;
  border-right: none;
  padding: 10px 6px;
}
.section_fair_main .data th:nth-child(4) {
  border-right: none;
}
.section_fair_main .data .fairtime {
  text-align: center;
}
.section_fair_main .data td {
  padding: 15px 6px;
  vertical-align: middle;
}
.section_fair_main .data.date_box td {
  text-align: left;
  padding: 10px 20px;
}
.section_fair_main .data .note {
  width: 35%;
}
.section_fair_main .data .ex dt, .section_fair_main .data .ex dd {
  font-size: 12px;
  font-weight: normal;
  float: left;
}
.section_fair_main .data .ex dt:after {
  content: ": ";
}
.section_fair_main .data .ex dd {
  margin-right: 2em;
}
.section_fair_main .data .reserve_time a {
  width: 100%;
}

.section_fair_main .data02 {
  width: 100%;
}

.section_fair_main .data02 th, .section_fair_main .data02 td {
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
}
.section_fair_main .data02 th {
  font-weight: bold;
  padding: 10px 6px;
  color: #fff;
  background: #272727;
  letter-spacing: 1px;
  width: 20%;
  border: 1px solid #333;
}
.section_fair_main .data02 td {
  border: 1px solid #333;
  padding: 14px 20px;
  text-align: left;
  word-break: break-all;
}
.section_fair_main .data02 span {
  padding-right: 20px;
  display: inline-block;
}
.section_fair_main .txtbox {
  margin-top: 30px;
  margin-bottom: 40px;
}
.section_fair_main .txtbox .caption {
  border-bottom: 1px solid #2d2d2d;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.section_fair_main .txtbox .value_box {
  padding: 20px;
  background: #faf6f1;
  margin-top: 40px;
}
.section_fair_main .txtbox .value_box .tit {
  border-top: 1px solid #141414;
  border-bottom: 1px solid #141414;
  color: #492900 !important;
  font-size: 16px;
  text-align: center;
  padding: 5px 0;
  margin: 0px 0 15px;
}
.text03 {
  text-align: left;
}
.section_fair_main .txtbox .value_box span {
  color: #ffff6e;
  padding-left: 3px;
}
.fair_box {
  width: 980px;
  margin: 25px auto 0;
  z-index: 999;
  position: relative;
}
ul.icon_info {
  display: block;
  margin: 0;
}
ul.icon_info li {
  float: left;
  display: block;
  margin: 0 0px 3px 0;
}
.section_fair_btns .btn05 a {
  display: block;
  font-size: 1.2em;
  text-align: center;
  border-top: 1px solid #141414;
  border-bottom: 1px solid #141414;
  color: #492900;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  width: 520px;
  padding: 14px 0 20px;
  margin: 0 auto 20px;
  letter-spacing: 1px;
}
.section_fair_btns .btn05 a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 5px;
  width: 0;
  height: 0;
  margin: -3px 0 0 0;
  border: 3px solid transparent;
  border-left: 5px solid #141414;
}
.section_fair_btns span.txt_sub {
  display: block;
  font-size: 14px;
  line-height: 14px;
}
iframe {
  width: 100% !important;
}
.section_fair_main .form_menu {
}
.section_fair_main .form_menu .reserve_time {
  border-top: 1px dotted #2d2d2d;
  border-bottom: 1px dotted #2d2d2d;
  padding: 20px 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
}
.section_fair_main .form_menu .reserve_time .tit_form {
  display: inline-block;
  margin-right: 10px;
}
.section_fair_main .form_menu .reserve_time .select_box {
  display: inline-block;
}
.section_fair_main .form_menu .btn_reserve {
  width: 342px;
  margin: 0 auto;
  position: relative;
}
.section_fair_main .form_menu .btn_reserve::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border: 3px solid transparent;
  border-left: 5px solid #141414;
  transition-duration: 200ms;
}
.section_fair_main .form_menu .btn_reserve input {
  width: 342px;
  padding: 18px 0;
  -webkit-appearance: none;
  background: rgba(190,171,144,.4);
  border: none;
  color: #141414;
  font-size: 16px;
  border-radius: 0;
}
.reserve_time a {
  display: block;
  font-size: 1.2em;
  text-align: center;
  background: #d99b85;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
  position: relative;
  width: 200px;
  margin: 0 auto;
  letter-spacing: 1px;
  height: 72px;
  line-height: 72px;
}
.reserve_time.btn_time a {
  display: block;
  font-size: 1.2em;
  text-align: center;
  background: #deb44c;
  box-sizing: border-box;
  text-decoration: none;
  color: #FFF;
  position: relative;
  width: 200px;
  margin: 0 auto;
  letter-spacing: 1px;
  height: 48px;
  line-height: 48px;
}
.reserve_time a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  margin: -3px 0 0 0;
  border: 3px solid transparent;
  border-left: 5px solid #FFF;
}
.section_plan_main.fair {
  background: none;
  padding: 0px;
  border-style: none;
}
.main_fair .section_plan_main .btn {
  vertical-align: bottom;
}
.main_fair .section_plan_main .btn a {
  font-size: 15px;
}
.main_fair .section_plan_main .btn a span {
  display: block;
  font-size: 16px;
}
.main_fair .section_plan_main .btn_plan .btn a {
  padding: 24px 0 23px;
}
.section_plan_main .unit .ph img {
  width: 100%;
  margin-top: -200px;
}


/* ================================
 *
 * プラン
 *
 * ================================ */

/*---------------------------------------------------
 plan_index
---------------------------------------------------*/

.main_plan {
  padding: 0px 0 60px;
  width: 1000px;
  margin: 0 auto;
}
.main_plan.fair {
  padding: 0;
  border-top: none;
}

/*-------------------------------------------
section_plan_list
-------------------------------------------*/

.section_plan_list {
  margin: 0 auto;
}
.section_plan_list h4 {
  font-size: 29px;
  letter-spacing: 2px;
  text-align: center;
  line-height: 1.2;
  background: #fff;
  width: 254px;
  margin: -17px auto 0;
 font-weight: bold;
}
.section_plan_list .catch {
  font-size: 14px;
  text-align: center;
  margin-bottom: 24px;
}
.section_plan_list .box {
    height: 160px;
    background: #FFF;
    float: left;
    position: relative;
    border: 1px solid #e9e7e5;
    width: 48%;
    margin: 1% 1%;
}

.section_plan_list .box a{
  width:100%;
  height: 100%;
  display:block;
  padding:14px;
  box-sizing:border-box;
  position:relative;
}

.section_plan_list .box .ph{
  width:200px;
  height:129px;
  overflow:hidden;
  float:left;
}

.section_plan_list .box .ph img{
  width:100%;
}

.section_plan_list .box .txt_box{
  float:right;
  width:calc(100% - 220px);
  position:relative;
  text-align:left;
}

.section_rank .box .txt_box:after{
  display: block;
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border: 3px solid transparent;
  border-left: 5px solid #2d2d2d;
  transition-duration: 200ms;
  z-index: 99;
}

.section_plan_list .box .title {
  font-size: 16px;
  max-height: 50px;
  overflow: hidden;
  line-height: 1.4;
  margin-bottom: 10px;
}

.section_plan_list .box .txt{
  font-size:13px;
  height:60px;
  overflow:hidden;
}




/*---------------------------------------------------

 Plan_detail

---------------------------------------------------*/

/*-------------------------------------------
section_plan_main
-------------------------------------------*/

.section_plan_main {
  width: 100%;
  background: #fff;
  padding: 40px;
  margin-bottom: 30px;
}
.section_plan_main .unit {
  padding: 0 38px 38px;
  margin-bottom: 65px;
  text-align: center;
}
.section_plan_main .ph {
  width: 100%;
  margin-bottom: 30px;
}
.section_plan_main .ph img {
  width: 100%;
}
.section_plan_main .unit .tit {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 30px;
  text-align: center;
}
.section_plan_main .unit .stit {
  font-size: 18px;
  color: #1f1f1f;
  margin-bottom: 20px;
  text-align: center;
}
.section_plan_main .unit .caption {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.section_plan_main .unit .tbl_plan {
  border-collapse: collapse;
  margin: 0 auto 30px;
}
.section_plan_main .unit .tbl_plan dt {
  padding: 3px 30px;
  text-align: center;
  background: #1f1f1f;
  color: #fff;
  display: inline-block;
  vertical-align: top;
}
.section_plan_main .unit .tbl_plan dd {
  max-width: 33%;
  padding-right: 30px;
  display: inline-block;
  margin-left: 15px;
  text-align: left;
  vertical-align: top;
}
.section_plan_main .unit .tbl_plan dd:last-child {
  padding-right: 0;
}
.section_plan_main .unit .price {
  padding: 15px 0;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
}
.section_plan_main .unit .price span {
  display: block;
  font-size: 13px;
}
.section_plan_main .unit .txt {
  margin-bottom: 30px;
}
.section_plan_main .unit .txt img {
  margin: 10px 0;
}
.section_plan_main .unit .value_box {
  margin-bottom: 50px;
  background: #f4e7c5;
  padding: 20px 24px;
}
.section_plan_main .unit .value_box .tit {
  border-top: 1px solid #141414;
  border-bottom: 1px solid #141414;
  color: #492900 !important;
  font-size: 16px;
  text-align: center;
  padding: 5px 0;
  margin: 0px 0 15px;
}
.section_plan_main .unit .value_box .text03 {
  color: #111;
  font-weight: normal;
  line-height: 1.6;
  margin-bottom: .6em;
  font-size: 1.1em;
  text-align: left;
}
.section_plan_main .unit .value_box span {
  color: #ffff6e;
  padding-left: 3px;
}
.section_plan_main .btn_plan {
  text-align: center;
  margin-top: 60px;
}
.section_plan_main .btn_plan .btn {
  display: inline-block;
  width: 350px;
  height: 65px;
  margin: 0 10px;
}
.section_plan_main .btn a {
  width: 100%;
  display: block;
  line-height: 1.4;
  padding: 13px 0;
  background: #deb44c;
  color: #FFF;
  font-size: 17px;
}
.section_plan_main .btn a span {
  display: block;
  font-size: 12px;
}
.section_plan_main .btn a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  margin: -3px 0 0 0;
  border: 3px solid transparent;
  border-left: 5px solid #FFF;
}
.section_plan_main .btn_plan .btn:nth-child(2) a {
  border-top: 1px solid #141414;
  border-bottom: 1px solid #141414;
  color: #492900;
  box-sizing: border-box;
  background: #fff;
}
.section_plan_main .txtbox .tit {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  padding-bottom: 18px;
  border-bottom: 1px solid #2d2d2d;
}
.section_plan_main .data {
  margin-bottom: 40px;
}
.section_plan_main .data table {
  margin-bottom: 8px;
  width: 100%;
  border-collapse: collapse;
}
.section_plan_main .data tbody tr {
  border-bottom: 1px rgba(0,0,0,0.2) solid;
}
.section_plan_main .data tbody tr:first-child {
  border-top: 1px rgba(0,0,0,0.08) solid;
}
.section_plan_main .data th, .section_plan_main .data td {
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
}
.section_plan_main .data th {
  padding: 10px 6px;
  background: rgba(0,0,0,.1);
  letter-spacing: 1px;
}
.section_plan_main .data th:nth-child(1) {
  width: 30%;
  border-right: 1px solid #FFF;
}
.section_plan_main .data th:nth-child(2) {
  width: 70%;
}
.section_plan_main .data td {
  padding: 10px 6px;
  vertical-align: middle;
}
.section_plan_main .data td.data {
  padding: 13px 20px;
  text-align: left;
}
.section_plan_main .data .note {
  width: 35%;
}
.section_plan_main .data .ex dt, .section_plan_main .data .ex dd {
  font-size: 12px;
  font-weight: normal;
  float: left;
}
.section_plan_main .data .ex dt:after {
  content: ": ";
}
.section_plan_main .data .ex dd {
  margin-right: 2em;
}
/* section_other
---------------------------------------------------*/

.main_plan .section_other {
  width: 840px;
  margin: 50px auto 30px;
  text-align: center;
  position: relative;
  padding: 0!important;
  background: #fff!important;
}
.main_plan.fair .section_other {
  width: 1000px;
  margin: 0 auto;
  text-align: left;
  padding: 0!important;
  background: #fff!important;
}
.main_plan .section_other .unit {
  width: 390px;
  display: inline-block;
  margin: 0 10px;
  text-align: left;
  position: relative;
}
.main_plan.fair .section_other .unit {
  width: 472px;
  margin-bottom: 20px;
}
.main_plan .section_other .unit a {
  display: block;
  padding: 12px;
  background: #fff;
	border: 1px solid #e9e7e5;
}
.main_plan .section_other .unit::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 15px;
  right: 7px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border: 3px solid transparent;
  border-left: 5px solid #2d2d2d;
  transition-duration: 200ms;
  z-index: 99;
}
.main_plan .section_other .unit .ph {
  width: 148px;
  height: 96px;
  overflow: hidden;
  float: left;
  margin-right: 12px;
}
.main_plan.fair .section_other .unit .ph {
  width: 200px;
  height: 132px;
}
.main_plan .section_other .unit .ph img {
  width: 100%;
}
.main_plan .section_other .unit .box {
  width: 192px;
  float: left;
  padding-top: 5px;
}
.main_plan.fair .section_other .unit .box {
  width: 232px;
}
.main_plan .section_other.plan .unit .box {
  padding-top: 0px;
}
.main_plan .section_other .unit .box .tit {
  margin-bottom: 5px;
  font-size: 15px;
  height: 56px;
}
.main_plan.fair .section_other .unit .box .tit {
  height: 74px;
  overflow: hidden;
}
.main_plan.fair .section_other .unit .box .icon {
  text-align: left;
  margin-bottom: 5px;
}
.main_plan .section_other.plan .unit .box .tit {
  height: 48px;
  overflow: hidden;
}
.main_plan .section_other .unit .box .catch {
  height: 28px;
  overflow: hidden;
}
.main_plan .section_other .unit .box .price {
  height: 42px;
  overflow: hidden;
}
.main_plan .section_other .btn_prev {
  position: absolute;
  top: 50px;
  left: -30px;
}
.main_plan .section_other .btn_next {
  position: absolute;
  top: 50px;
  right: -30px;
}


/* ================================
 *
 * レポート
 *
 * ================================ */

/*---------------------------------------------------
 Report_index
---------------------------------------------------*/

.main_report {
  width: 1000px;
  margin: 0 auto;
  padding-bottom: 60px;
}
.section_report_list {
  width: 1000px;
  margin: 0 auto;
}
.section_report_list .report li.box {
  width: 316px;
  height: 204px;
  float: left;
  margin-right: 22px;
  margin-bottom: 22px;
  text-align: center;
  position: relative;
  background: rgba(156,153,143,.5);
}
.section_report_list .report li.box:nth-child(3n) {
  margin-right: 0;
}
.section_report_list .report li.box a .txt_wrapper {
  width: 316px;
  height: 204px;
  padding: 60px 20px;
  position: absolute;
  color: #141414;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
}
.section_report_list .report li.box a:hover,
.section_report_list .report li.box a:hover .txt_wrapper {
  opacity: 1;
}
.section_report_list .report li.box .ph {
  width: 316px;
  height: 204px;
  overflow: hidden;
  margin-bottom: 10px;
}
.section_report_list .report li.box .ph img {
  width: 100%;
}
.section_report_list .report li.box .ph img {
  width: 100%;
}
.section_report_list .report li.box:hover .ph img {
  -webkit-filter: blur(5px) opacity(0.3);
  -moz-filter: blur(5px) opacity(0.3);
  -o-filter: blur(5px) opacity(0.3);
  -ms-filter: blur(5px) opacity(0.3);
  filter: blur(5px) opacity(0.3);
}
.section_report_list .report li.box .tit {
  font-size: 15px;
  font-family: "a-otf-ryumin-pr6n", 游明朝, YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", HG明朝E, "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 27px;
}
.section_report_list .report li.box .date {
  position: relative;
}
.section_report_list .report li.box a:hover .date {
  color: #2d2d2d;
}
.section_report_list .report li.box .date:after {
  position: absolute;
  width: 150px;
  height: 1px;
  content: "";
  background: #2d2d2d;
  top: -14px;
  left: 50%;
  margin-left: -75px;
}

.main_report.main_movie {
  width: 1000px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.main_report.main_movie .iframe_wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.main_report.main_movie .report li.box {
    position: relative;
    float: left;
    width: 316px;
    height: 204px;
    margin-right: 22px;
    padding-bottom: 100px;
    margin-bottom: 110px;
    text-align: center;
    background: rgba(156, 153, 143, .5);
}

.main_report.main_movie .report li.box a .txt_wrapper {
  position: absolute;
    width: 316px;
    height: 204px;
    padding-top: 215px;
    opacity: 10;
    color: #2d2d2d;
}



.main_report.main_movie .report li.box {
    position: relative;
    float: left;
    width: 316px;
    height: 204px;
    margin-right: 22px;
    padding-bottom: 100px;
    margin-bottom: 110px;
    text-align: center;
    background: rgba(156, 153, 143, .5);
}

.main_report.main_movie .report li.box:nth-child(3n){
	margin-right: 0;
}




/*---------------------------------------------------

 movie_detail

---------------------------------------------------*/

.main_report.movie_detail .iframe_wrap{
	height: 600px;
	margin-bottom: 15px;
}
/*---------------------------------------------------

 Report_detail

---------------------------------------------------*/

.section_report_article {
  width: 100%;
  padding-top: 20px;
  margin-bottom: 70px;
}
.section_report_article.detail .photo01.main {
  margin-bottom: 40px;
}
.section_report_article .inner {
}
.report_data {
  text-align: center;
}
.report_data .report_date {
  margin-bottom: 10px;
}
.report_data .icon {
}
.report_data .icon li {
  display: inline-block;
}
.tbl_report {
  border-collapse: collapse;
  margin: 0 auto 10px;
}
.tbl_report dt {
  padding: 3px 30px;
  text-align: center;
  background: #eedcd3;
  color: #141414;
  display: inline-block;
  vertical-align: top;
}
.tbl_report dt.empty {
  display: none;
}
.tbl_report dd {
  max-width: 33%;
  padding-right: 30px;
  display: inline-block;
  margin-left: 15px;
  text-align: left;
  font-size: 14px;
}
.tbl_report dd:last-child {
  padding-right: 0;
}
.report_tit {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}
.report_name {
  text-align: center;
  font-size: 20px;
}
.report_people {
  text-align: center;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.report_people p {
  display: inline-block;
  margin-bottom: 0!important;
  padding: 0 10px;
}
.section_report_article .main_block {
  width: 750px;
  margin: 40px auto;
}
.section_report_article p, .section_report_article .photo01, .section_report_article .photo02 {
  margin-bottom: 20px;
}
.section_report_article p {
  font-size: 15px;
  line-height: 2;
  text-align: center;
}
.section_report_article .photo01 img {
  display: block;
  width: 100%;
}
.section_report_article .photo02 {
 *zoom: 1;
}
.section_report_article .photo02:after {
  font-size: 0;
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: " ";
}
.section_report_article .photo02 li {
  overflow: hidden;
  width: 364px;
}
.section_report_article .photo02 li:first-child {
  float: left;
}
.section_report_article .photo02 li:last-child {
  float: right;
}
.section_report_article .photo02 li img {
  display: block;
  height: auto;
}
.section_report_article .unit_faq {
  margin-bottom: 50px;
}
.section_report_article .unit_faq dt, .section_report_article .unit_faq dd {
  font-size: 14px;
  line-height: 2em;
}
.section_report_article .unit_faq dt {
  font-weight: bold;
  font-size: 15px;
}
.section_report_article .unit_faq dd:last-child {
  margin-bottom: 0;
}
.section_report_article .unit_data table {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px #ccc solid;
}
.section_report_article .unit_data tr {
  border-bottom: 1px #ccc solid;
}
.section_report_article .unit_data td {
  font-size: 13px;
  padding: 16px 0;
}
.section_report_article .unit_data th {
  font-weight: bold;
  width: 240px;
  text-align: left;
  background: #f7f2ee;
  padding: 16px;
  font-size: 13px;
}
.section_report_article .unit_data td {
  padding: 16px 32px;
}
.section_report_article.new {
  position: relative;
}
.section_report_btn ul {
  width: 652px;
  margin: 0 auto;
}
.section_report_btn li {
  line-height: 0;
  float: left;
  margin-right: 4px;
}
.section_report_btn li:last-child {
  margin-right: 0;
}


/* other_report
---------------------------------------------------*/

.main_report .other_report {
  width: 840px;
  margin: 50px auto 30px;
  text-align: left;
  position: relative;
}
.main_report .other_report .unit {
  width: 390px;
  display: inline-block;
  margin: 0 10px 10px;
  text-align: left;
  position: relative;
}
.main_report .other_report .unit a {
  display: block;
  padding: 12px;
  border: 1px solid #141414;
}
.main_report .other_report .unit::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 7px;
  right: 7px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border: 3px solid transparent;
  border-left: 5px solid #2d2d2d;
  transition-duration: 200ms;
  z-index: 99;
}
.main_report .other_report .unit .ph {
  width: 148px;
  height: 96px;
  overflow: hidden;
  float: left;
  margin-right: 12px;
}
.main_report .other_report .unit .ph img {
  width: 100%;
}
.main_report .other_report .unit .box {
  width: 192px;
  float: left;
}
.main_report .other_report .unit .box .tit {
  margin-bottom: 5px;
  font-size: 14px;
  height: 47px;
}
.main_report .other_report .unit .box .catch {
  height: 28px;
  overflow: hidden;
}
.main_report .other_report .unit .box .icon {
  text-align: left;
}



/* ================================
 *
 * ギャラリー
 *
 * ================================ */

.main_gallery {
  width: 1000px;
  margin: 0 auto;
  padding: 0px 0 60px;
}
div, dl, ul {
  opacity : 1.0;
  transition : none;
}
.main_gallery ul.gallery {
  opacity: 1.0;
  transition: none;
}
.main_gallery ul.gallery li {
  width: 239px;
  height: 232px;
  overflow: hidden;
  float: left;
  margin: 0 14px 14px 0;
  position: relative;
}
.main_gallery ul.gallery li:nth-child(4n) {
  margin: 0 0 14px 0;
}
.main_gallery ul.gallery li img {
  width: 100%;
}
.main_gallery p.btn_open {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #242424;
  text-align: center;
  margin: 35px auto 20px;
  padding-top: 60px;
  font-size: 16px;
  position: relative;
}
.main_gallery p.btn_open::after {
  display: block;
  content: "";
  width: 30px;
  height: 37px;
  background: url(../gallery/img/ico_more.png) no-repeat;
  background-size: 30px auto;
  position: absolute;
  top: 30px;
  left: 50%;
  margin-left: -15px;
}
.main_gallery p.btn_open:hover {
  cursor: pointer;
  opacity: .8;
}
.main_gallery .area_more {
  display: none;
}
.main_gallery .btn_more {
  display: block;
  text-align: center;
  background: #1f1f1f;
  text-decoration: none;
  color: #fff;
  position: relative;
  cursor: pointer;
  width: 300px;
  height: 40px;
  line-height: 40px;
  margin: 0px auto 20px;
}

.main_gallery .btn_more.open:after {
  transform: rotate(270deg);
  margin-top: -7px;
}
.main_gallery .btn_open .label_open {
  display: inline;
}
.main_gallery .btn_open.open .label_open {
  display: none;
}
.main_gallery .btn_open .label_close {
  display: none;
}
.main_gallery .btn_open.open .label_close {
  display: inline;
}
.main_gallery .comment {
  font-size: 30px;
  text-align: center;
}
.main_gallery .btn_open.hidden, .comment.hidden {
  display: none;
}

.main_gallery.main_naka2 .container{
  padding-bottom: 100px;
}

/* ================================
 *
 * 新着・ブログ
 *
 * ================================ */

/* index
--------------------------------------------------*/

.main_news {
  padding-bottom: 60px;
  width: 1000px;
  margin: 0 auto;
}
.main_news h3.tit {
  font-size: 18px;
  text-align: center;
  padding: 12px 20px;
  border-top: 1px solid #1f1f1f;
  border-bottom: 1px solid #1f1f1f;
  margin-bottom: 32px;
  letter-spacing: 1.0px;
}
.main_news h3.tit_category {
  opacity: 1.0;
}

.section_last {
  margin-bottom: 50px;
  opacity : 1.0;
  transition : none;
}
.section_last div, .section_last dl, .section_last ul {
  opacity : 1.0;
  transition : none;
}
.section_last .category_list {
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 20px 20px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 30px;
}
.section_last .category_list li {
  display: inline-block;
}
.section_last .category_list li::after {
  content: "・";
}
.section_last .category_list li:last-child::after {
  content: "";
}
.section_last .category_list li a {
  padding: 0 7px;
}
.section_last .box_l .box {
  width: 620px;
}
.section_last .box_l .box .ph {
  width: 620px;
  height: 408px;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
}
.section_last .box .ph.new {
  position: relative;
}
.section_last .box .ph.new:after {
  display: block;
  content: "";
  width: 59px;
  height: 59px;
  background: url(../news/img/icon_new.png) no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
.section_last .box_l .box .ph img {
  width: 100%;
}
.section_last .box_l .box .tit {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.section_last .box .date {
  font-size: 14px;
  color: #a1827d;
}
.section_last .box a:hover .date {
  color: #a1827d;
}
.section_last .box_r {
  width: 372px;
}
.section_last .box_r .box {
  width: 182px;
  float: left;
  margin-bottom: 16px;
}
.section_last .box_r .box:nth-child(2n) {
  float: right;
}
.section_last .box_r .ph {
  width: 182px;
  height: 119px;
  overflow: hidden;
}
.section_last .box_r .ph img {
  width: 100%;
}
.section_last .box_r .box .tit {
  font-size: 14px;
  height: 64px;
  overflow: hidden;
  line-height: 1.4;
}
.next_btn {
  clear: both;
}
.next_btn p a {
  display: block;
  text-align: center;
  background: #1f1f1f;
  text-decoration: none;
  color: #fff;
  position: relative;
  width: 300px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
}
.next_btn p a:hover, li.next a:hover, li.before a:hover {
  background: #e08268;
}
.main_news .pager li.next a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border: 4px solid transparent;
  border-left: 5px solid #fff;
  transition-duration: 200ms;
}
.main_news .pager li.before a:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  width: 0;
  height: 0;
  margin-top: -4px;
  border: 4px solid transparent;
  border-right: 5px solid #fff;
  transition-duration: 200ms;
}
.main_news .pager {
  text-align: center;
  position: relative;
  z-index: 1;
  clear: both;
}
.main_news .pager li.next, .main_news .pager li.before {
  display: inline-block;
  text-align: center;
  position: relative;
  width: 239px;
  height: 37px;
  margin-top: 36px;
}
.main_news .pager li.next a, .main_news .pager li.before a {
  background: #2d2d2d;
  text-decoration: none;
  color: #fff;
  line-height: 40px;
  display: block;
}


/* news_box
--------------------------------------------------*/

.short .section .box {
  display: block;
  width: 372px;
  height: 126px;
  float: left;
  margin: 0 31px 31px 0;
  position: relative;
  cursor: pointer;
  z-index: 999;
}
.short .section .box a {
  width: 100%;
  display: block;
}
.short .section .box:nth-of-type(2n) {
  margin: 0 0 31px 0;
}
.short .section .box .ph {
  width: 190px;
  height: 126px;
  overflow: hidden;
  position: relative;
  float: left;
}
.short .section .box .txt_box {
  width: calc(100% - 200px);
  float: right;
}
.short .section .box .ph.new {
  position: relative;
}
.short .section .box .ph.new:after {
    font-family: "Playfair Display", serif;
    font-size: 13px;
    font-weight: 400;
    font-style: normal;
    line-height: 50px;
    position: absolute;
    top: 4px;
    left: 4px;
    display: block;
    width: 50px;
    height: 50px;
    content: "NEW";
    text-align: center;
    letter-spacing: 1px;
    color: #fff;
    border-radius: 50%;
    background: #deb44c;
}
.short .section .box .ph img {
  width: 100%;
}
.short .section .box .tit {
  font-size: 15px;
  line-height: 1.4;
  height: 45px;
  margin-top: 8px;
  margin-bottom: 5px;
  overflow: hidden;
}
.short .section .box .date {
  color: #deb44c;
  font-size: 14px;
  letter-spacing: 1px;
}
.short .section .box a:hover .date {
  color: #a1827d;
}


/* side
--------------------------------------------------*/

.side_tit {
  background: #1f1f1f;
  color: #fff;
  padding: 7px 0;
  text-align: center;
  width: 100%;
}
.archive {
  margin-bottom: 30px;
}
.archive a {
  text-decoration: none;
}
.archive .nextback {
  height: 34px;
  padding: 0;
  text-align: center;
  position: relative;
  background: #f4e7c5;
}
.archive .nextback .back, .archive .nextback .next {
  width: 30%;
}
.archive .nextback .back {
  float: left;
}
.archive .nextback .next {
  float: right;
}
.archive .nextback .back a, .archive .nextback .next a {
  color: #2d2d2d;
  position: relative;
  display: block;
  padding: 0;
  width: 100%;
  height: 34px;
  line-height: 34px;
  letter-spacing: 1.0px;
}
.archive .nextback .back a {
  padding-left: 10px;
}
.archive .nextback .next a {
  padding-right: 10px;
}
.archive .nextback .back a:hover, .archive .nextback .next a:hover {
  color: #e77b5e;
  text-decoration: none;
}
.archive .nextback .back a span, .archive .nextback .next a span {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 12px;
}
.archive .nextback .back a span:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: -10px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border: 4px solid transparent;
  border-right: 5px solid #1f1f1f;
  transition-duration: 200ms;
}
.archive .nextback .next a span:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  width: 0;
  height: 0;
  margin-top: -3px;
  border: 4px solid transparent;
  border-left: 5px solid #1f1f1f;
  transition-duration: 200ms;
}
.archive .nextback p.yearbox {
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  height: 34px;
  text-align: center;
  width: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -25%;
  letter-spacing: 1px;
}
.archive ul li a {
  border-bottom: 1px dotted #888;
  color: #333;
  display: block;
  padding: 9px 15px 8px 15px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  text-align: left;
}
.archive ul li a:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 5px;
  width: 0;
  height: 0;
  margin-top: -5px;
  border: 4px solid transparent;
  border-left: 5px solid #1f1f1f;
  transition-duration: 200ms;
}
.archive ul li a:hover {
  color: #e77b5e;
}


/* section_detail
--------------------------------------------------*/

.section_detail {
  margin-bottom: 80px;
}
.section_detail .inner {
  width: 100%;
  background: #fff;
  padding: 0;
  margin-bottom: 30px;
}
.section_detail .inner.detail {
  width: 100%;
  background: #fff;
  padding: 0;
  border: 1px solid #141414;
  margin-bottom: 30px;
}
.section_detail .inner.detail, .section_detail .inner.detail div, .section_detail .inner.detail ul {
  opacity: 1.0;
  transform: none;
}
.section_detail .unit {
  margin-bottom: 0px;
}
.section_detail .unit .ph {
  width: 500px;
  height: 322px;
  overflow: hidden;
  position: relative;
  float: left;
}
.section_detail .unit .ph img {
  width: 100%;
}
.section_detail .unit .ph.new:after {
    font-family: "Playfair Display", serif;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 59px;
    position: absolute;
    top: 5px;
    left: 5px;
    display: block;
    width: 59px;
    height: 59px;
    content: "NEW";
    text-align: center;
    letter-spacing: 1px;
    color: #FFF;
    border-radius: 50%;
    background: #deb44c;
}
.section_detail .unit .txtbox {
  width: 475px;
  float: right;
  padding-top: 45px;
  padding-right: 45px;
}
.section_detail .cnt_box {
  padding: 50px;
}
.section_detail .unit .icon {
  margin-bottom: 18px;
}
.section_detail .unit .tit {
  font-size: 18px;
  line-height: 1.6;
}
.section_detail .unit .date {
  font-size: 16px;
  margin-bottom: 18px;
}




/* ================================
 *
 * システム吐き出し用CSS
 *
 * システムから出力されるテキスト全体を囲む<div>等に
 * class="system_article"を付与すること。
 *
 * ================================ */

/* 文字色・文字サイズなどのデフォルト設定 */
.system_article p, .system_article ul, .system_article ol {
  color: #111 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 1.4 !important;
  margin-bottom: .6em !important;
}
/* リンクテキスト */
.system_article a {
  color: #37d !important;
  text-decoration: underline !important;
}
.system_article a:hover {
  text-decoration: none !important;
}
.system_article a:active {
  text-decoration: underline !important;
}
/* 左揃え・中央揃え・右揃え */
.system_article p {
  min-height: 1.4em;
 *zoom: 1;
}
.system_article p::after {
  font-size: 0;
  display: block;
  visibility: hidden;
  clear: both;
  height: 0;
  content: " ";
}
.alignleft {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  clear: both;
}
.alignright {
  float: right;
  margin-left: 20px;
  margin-bottom: 20px;
  clear: both;
}
.system_article .aligncenter, .system_article img.aligncenter {
  clear: both !important;
  display: block !important;
  margin: 0 auto !important;
}
/* 写真のキャプションの揃え */
.wp-caption-text {
  text-align: center;
}
/* 箇条書きリスト */
.system_article ul, .system_article ol {
  padding-left: 1.5em !important;
}
.system_article ul {
  list-style: disc !important;
}
.system_article ol {
  list-style: decimal !important;
}
/* 太字 */
.system_article strong {
  font-weight: boldl !important;
}
/* 斜体 */
.system_article em {
  font-style: italic !important;
  font-family: "ＭＳ ゴシック", "MS Gothic", "Osaka－等幅", Osaka-mono, monospace;
  font-weight: normal;
}
/* 見出しの基本設定 */
.system_article h1, .system_article h2, .system_article h3 {
  clear: both;
  font-weight: bold;
  line-height: 1.4;
}
.system_article h1:first-child, .system_article h2:first-child, .system_article h3:first-child {
  margin-top: 0;
}
.system_article h1 {
  margin: 2.4em 0 1.2em;
  font-size: 1.25em;
}
.system_article h2 {
  margin: 2em 0 1em;
  font-size: 1.25em;
}
.system_article h3 {
  margin: 1.6em 0 .8em;
  font-size: 1.2em;
}
/* 表組み */
.system_article table {
  max-width: 100%;
  margin: 1em 0 1.4em;
  border-collapse: collapse;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.system_article th, .system_article td {
  font-size: .9em;
  padding: .4em;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.system_article th {
  text-align: center;
  background-color: #eee;
}
.system_article img, .system_article video {
  max-width: 100%;
}
